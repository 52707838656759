html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

body {
    font-family: 'Lato', sans-serif;
    background-color: #042594;
    color: #fff;
}

p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

h1 {
    font-weight: 900;
    font-size: 72px;
    margin: 56px 0px;
}

h1 .slim {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    margin-bottom: 42px;
}

h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
}

b {
    font-weight: 900;
}

section:nth-of-type(1) h2 {
    margin-bottom: 56px;
}

section p {
    line-height: 32px;
    font-weight: 400;
    font-style: normal;
    color: #FAFAFA;
}

section p:not(:last-child) {
    margin-bottom: 42px;
}

.d-none {
    display: none;
}

.text-center {
    text-align: center;
}

.m-center {
    margin: 0 auto;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 24px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    text-decoration: none;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-header {
    width: 100%;
    padding-bottom: 54px;
    position: relative;
    overflow: hidden;
}

.header-image {
    background-image: url('../image/header.webp');
    background-repeat: no-repeat;
    background-position: right center;
    position: absolute;
    width: 723px;
    height: 750px;
    top: 96px;
    right: -274px;
}
 
.site-header .content-wrapper {
    width: 772px;
}

.site-header .container {
    position: relative;
    z-index: 10;
}

.site-header .container .blur-shape {
    position: absolute;
    width: 399px;
    height: 399px;
    left: -200px;
    top: 42px;
    background: rgba(138, 213, 241, 0.3);
    filter: blur(26px);
}

.site-logo {
    display: block;
    width: 167px;
    height: 27px;
    line-height: 27px;
    /* background-image: url('../image/logo.png'); */
    background-repeat: no-repeat;
    color: #FAFAFA;
    text-decoration: none;
}

.header-description {
    margin-bottom: 82px;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    list-style: none;
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.nav-wrapper li {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.nav-wrapper a {
    box-shadow: inset 0 0 0 0 #2CB172;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
    display: block;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    text-indent: 67px;
    color: #FAFAFA;
    width: 256px;
    height: 65px;
    line-height: 65px;
    border: 0;
    border-left: 8px solid #2CB172;
    background-color: rgba(96, 120, 210, 0.1);
    border-radius: 2px;
    margin-bottom: 58px;
    position: relative;
}

.nav-wrapper a:hover {
    opacity: 1;
    background-color: #2CB172;
    transition: all 0.2s linear;
    cursor: pointer;
    box-shadow: inset 0 0 0 50px #2CB172;
}

.nav-wrapper a:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    top: 16px;
    left: 20px;
}

.nav-wrapper li:nth-of-type(1) a:before {
    background-image: url('../image/cloud-data.png');
}

.nav-wrapper li:nth-of-type(2) a:before {
    background-image: url('../image/diamond.png');
}

.nav-wrapper li:nth-of-type(3) a:before {
    background-image: url('../image/job-offer.png');
}

.nav-wrapper li:nth-of-type(4) a:before {
    background-image: url('../image/about.png');
}

.block {
    padding-left: 50px;
    padding-bottom: 50px;
    padding-top: 34px;
    background-color: #021476;
    border-radius: 20px;
    margin-bottom: 42px;
}

.block.type1 {
    display: flex;
    justify-content: space-between;
    height: 312px;
}

.block h3 {
    margin-bottom: 26px;
}

.block .content p {
    color: #FAFAFA;
}

.block .content {
    width: 25%;
}

.block .visualization {
    width: 75%;
    position: relative;
}

.block .visualization .image {
    position: absolute;
}

.block .visualization .title-box {
    width: 204px;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    padding-left: 42px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 12px center;
    position: absolute;
}

.block .visualization .title-box.red {
    color: #FF4D00;
    background-image: url('../image/cross.png');
}

.block .visualization .title-box.green {
    color: #4ED495;
    background-image: url('../image/check.png');
}

#decentralization .group{
    height: 282px;
    width: 282px;
}

#decentralization .image1 {
    width: 253px;
    height: 200px;
    background-image: url('../image/group-1.png');
    top: 36px;
    left: 32px;
}

#title-box-centralized {
    top: 0px;
    left: 252px;
}

#decentralization .image2 {
    width: 281px;
    height: 194px;
    background-image: url('../image/group-2.png');
    right: 72px;
    top: 12px;
}

#title-box-decentralized {
    top: 148px;
    left: 322px;
}

#title-box-decentralized-2 {
    height: 64px;
    line-height: 30px;
    right: 256px;
    top: 138px;
}

#title-box-centralized-2 {
    width: 230px;
    left: 200px;
    top: 10px;
}

#efficiency .group{
    height: 260px;
    width: 200px;
}

#efficiency .image1 {
    width: 193px;
    height: 193px;
    background-image: url('../image/server.png');
    top: 36px;
    left: 58px;
}

#efficiency .image2 {
    width: 207px;
    height: 207px;
    background-image: url('../image/files.png');
    top: 23px;
    right: 79px;
}

.box-wrapper-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-wrapper-2 .box {
    width: 50%;
}

.box-wrapper-2 .box.content {
    padding-left: 30px;
}

.box-wrapper-2.our-offer {
    margin-top: 67px;
    margin-bottom: 78px;
}

.box-wrapper-2.our-offer .box:first-child {
    padding-left: 90px;
    padding-right: 94px;
    border-right:2px solid #04104E; 
    position:relative;
    z-index:10
}

.box-wrapper-2.our-offer .box:first-child::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0px;
    bottom: 0px;
    right: 1px;
    left: 0px;
    border-right: 2px solid #102AAC;
}

.nft-miniting .visualization > div  {
    width: 538px;
    height: 538px;
    background-image: url('../image/storage-1.webp');
}

.nft-miniting .mobile-image {
    display: none;
}

.box-wrapper-2.our-offer .box:last-child {
    padding-bottom: 42px;
    padding-left: 134px;  
}

#we-offer {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 24px;
}

.custom-ul {
    list-style: none;
    font-weight: 900;
}

.custom-ul li:before {
    content: ' ';
    display: block;
    width: 21px;
    height: 14px;
    background-image: url('../image/check.png');
    margin-right: 20px;
    margin-top: 4px;
    float: left;
}

.custom-ul li:not(:last-child) {
    margin-bottom: 20px;
}

.block.type2 {
    padding-top: 50px;
    padding-bottom: 104px;
    padding-right: 100px;
    margin-bottom: 56px;
    background: rgb(2,20,118);
    background: linear-gradient(145deg, rgba(2,20,118,1) 28%, rgba(4,37,148,1) 72%);
}

.btn-contact {
    display: inline-block;
    width: 256px;
    height: 65px;
    line-height: 65px;
    text-decoration: none;
    font-weight: 900;
    font-size: 18px;
    color: #FAFAFA;
    background-color: #2CB172;
    box-shadow:  0px 4px 33px 6px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    position: relative;
}

/* 
.btn-contact:hover::before{
    content: 'Services under construction';
    color: #1A464F;;
    font-size: 18px;
    position: absolute;
    width: inherit;
    height: inherit;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    border-radius: 2px;
    opacity: 0.95;
    box-shadow: 0px 4px 15px rgba(26, 70, 79, 0.3);
    transform: translateY(-80px);
}
.btn-contact:hover::after{
    content: "";
    clear: both;
    border-top: 8px solid #fff;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    opacity: 0.95;
    position: absolute;
    height: 0;
    left: 46%;
    width: 0;
    transform: translateY(-16px);
}
*/

.our-team .visualization > div  {
    width: 637px;
    height: 565px;
    background-image: url('../image/storage-2.webp');
    margin-left: -42px;
}

.our-team .box.content {
    padding-left: 48px;
}

.our-team {
    margin-bottom: 85px;
}

.disclamer{
    margin-bottom: 30px;
}
.disclamer p{
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: #FAFAFA;
}

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #828C98;
}

footer {
    padding: 35px 0px;
    background-color: #010C48;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 768px) {

    header, 
    article,
    footer {
        padding: 0px 21px;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 26px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }

    .header-image {
        width: 353px;
        height: 366px;
        background-image: url('../image/header-mobile.webp');
        z-index: -1;
        right: -21px;
        top: 55px;
    }

    .site-header .content-wrapper {
        width: 100%;
    }
    
    .blur-shape {
        display: none;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper a {
        width: 100%;
    }

    .nav-wrapper a {
        margin-bottom: 22px !important;
    }
    
    .block,
    .block.type2 {
        padding: 20px;
    }

    .block.type1 {
        display: block;
        height: auto;
    }

    .block .content,
    .block .visualization {
        width: 100%;
    }

    .block .visualization {
        padding-top: 32px;
    }

    .block .visualization .group {
        position: relative;
    }

    .block .visualization .group .element {
        position: absolute;
    }

    .block .visualization .image {
        position: relative;
        left:0 !important;
        right: initial !important;
    }

    .block .visualization .group1,
    .block .visualization .group2 {
        left: 50%;
        transform: translateX(-50%);
    }

    .block .visualization .group2 {
        margin-top: 20px;
    }

    #title-box-centralized {
        top: 0px;
        left: 35px;
    }

    #title-box-decentralized {
        top: -24px;
        left: 46px;
    }

    #title-box-centralized-2 {
        left: -12px;
        top: -38px;
    }

    #title-box-decentralized-2 {
        top: -76px;
        left: 0;
    }

    #efficiency .group1 {
        margin-top: 32px;
    }

    #efficiency .group2 {
        margin-top: 84px;
    }

    .box-wrapper-2 {
        display: block;
    }

    .box-wrapper-2 .box {
        width: 100%;
    }

    .nft-miniting .visualization {
        display: none;
    }

    .nft-miniting .mobile-image {
        display: block;
        width: 288px;
        height: 288px;
        background-image: url('../image/storage-1-mobile.png');
        margin: -92px auto 0px;
    }

    .box-wrapper-2 .box.content,
    .box-wrapper-2.our-offer .box:first-child,
    .box-wrapper-2.our-offer .box:last-child {
        padding: 25px 0;
    }

    .box-wrapper-2.our-offer {
        margin-top: 0px;
        margin-bottom: 24px;
    }

    .box-wrapper-2.our-offer .box:first-child {
        border-bottom: 2px solid #04104E;
        border-right: none;
    }

    .box-wrapper-2.our-offer .box:first-child::after {
        top: 0px;
        bottom: 0px;
        right: 1px;
        left: 0px;
        border-bottom: 2px solid #102AAC;
        border-right: none;
    }

    .our-team .visualization > div {
        background-image: url('../image/storage-2-mobile.png');
        width: 321px;
        height: 285px;
        margin: -84px auto 0px;
    }

    .our-team h2 {
        font-size: 42px;
    }

    .our-team {
        margin-bottom: 10px;
    }

    footer .container {
        display: block;
        text-align: center;
        padding-bottom: 40px;
        padding-top: 20px;
    }

    footer .container .logo-wrapper {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }
}

